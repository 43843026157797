import React from 'react';
import { Container } from 'react-bootstrap';
// import TechSlider from './tech_slider';

const Homepage = () => {
  return (
    <Container>
      <h3 id="repodev" className="text-center">
        REPODEV
      </h3>
      <p className="text-center">
        <em>Muh Ibnu Habil Hanafi</em>
      </p>
      <p className="text-center">
        I work as a Software Engineer and use Linux for work.
        Typically, I use opensource software while developing <em>backend</em> systems.
        I occasionally work on <em>front-end</em> development as well (small parts).
        I simply love software engineering.
      </p>{' '}
      <br />
      {/* <p>Tech list that i&apos;m using right now:</p>
       <TechSlider /> */}
    </Container>
  );
};

export default Homepage;
