import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import Homepage from '../components/homepage';
import Works from '../components/works';
import About from '../components/about';

const IndexPage = () => (
  <Layout>
    <Helmet title={'Repodev'} />
    <Homepage />
    <Works />
    <About />
  </Layout>
);

export default IndexPage;
