import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';

const contacts = [
  { link: 'https://github.com/nubilfi', platform: 'Github', font: 'fa-github' },
  {
    link: 'https://twitter.com/nubilfi',
    platform: 'Twitter',
    font: 'fa-twitter',
  },
  {
    link: 'https://www.linkedin.com/in/ibnuhabil',
    platform: 'Linkedin',
    font: 'fa-linkedin',
  },
  {
    link: 'https://www.youtube.com/channel/UCyAnKNcVOr6SWJGIlHh6TMQ',
    platform: 'Youtube',
    font: 'fa-youtube',
  },
];

const Wrapper = styled(Container)`
  padding-bottom: 15rem !important;
`;

const About = () => {
  return (
    <Wrapper>
      <h3 className="text-center">ABOUT</h3>
      <p className="text-center">
        <em>A little more about me.</em>
      </p>

      <Row>
        <Col md="4">
          <p>Get in touch with me:</p>
          {contacts.map((data) => (
            <p key={data.platform}>
              <span className={`fab ${data.font}`}></span>{' '}
              <a href={data.link} target="_blank" rel="noopener noreferrer">
                &nbsp;{data.platform}
              </a>
            </p>
          ))}{' '}
          <br />
        </Col>

        <Col md="8">
          <Row>
            <Col sm="12">
              <p>
                I've been working in Tech for about 7 years now, I provide solutions & team up with businesses that want to get past obstacles and reach their goals.
                I code primarily in the backend system. The frameworks that I usually use are ExpressJS, Laravel/Lumen/Phalcon, and Actix-web.
                I also code in the frontend though, I know VueJS and GatsbyJS, been using them for a while.
                I'm relatively new to the Rust language & its environment. My other expertise includes Linux, Docker, Git, PWA, MySQL/MariaDB, PostgreSQL & MongoDB.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default About;
