import React, { useState } from 'react';
import { Container, Button, Modal, Row, Col, Card } from 'react-bootstrap';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const projects = [
  {
    name: 'Laboratory Electronic Form',
    type: '(in-house project)',
    company: 'PT. Pulau Sambu Guntung (2015-2017)',
    technologies: 'PHP, JavaScript',
    descriptions:
      'This application is an electronic form (web-based). It offers user-friendly solutions that can be used for Laboratory analysts to easily write and save their data and do not need to use on paper form anymore (As a company needs to reduce spending on paper usage).',
  },
  {
    name: 'Warehouse Stock Organizer',
    type: '(in-house project)',
    company: 'PT. Pulau Sambu Guntung (2015-2017)',
    technologies: 'PHP, JavaScript',
    descriptions:
      'A warehouse is a vital place for large-scale food & beverages company. It takes so much time for the company to manage its finished good product stock by hand. By using this app, a department that handled the stock plan and shipping date would be easy to check which product and which warehouses the product been placed.',
  },
  {
    name: 'BC Coconut Raw Meat',
    type: '(in-house project)',
    company: 'PT. Pulau Sambu Guntung (2015-2017)',
    technologies: 'PHP, JavaScript',
    descriptions:
      'Coconut is a main based raw meat for company products. The products will be export to overseas and Bea Cukai (an Indonesian government agency under the Ministry of Finance that serves the community in the field of customs and excise) needs to know the price and which farmer the company bought their Coconut. This app is used by a department who handled transactions for the Coconut, all transaction data can be used for a report to Bea Cukai.',
  },
  {
    name: 'BC Item Monitoring',
    type: '(in-house project)',
    company: 'PT. Pulau Sambu Guntung (2015-2017)',
    technologies: 'PHP & Nodejs/ExpressJS, Handlebars',
    descriptions:
      'This app is focusing on monitoring all related items for the finished good products. Bea Cukai will be able to check all company products and every single item of the products, all data is recorded on the database and will be displayed in this app as a report.',
  },
  {
    name: 'Tools Watcher',
    type: '(in-house project)',
    company: 'PT. Pulau Sambu Guntung (2015-2017)',
    technologies: 'Nodejs/Express, Handlebars, JavaScript',
    descriptions:
      'Tools Watcher is designed to record toolkit transactions in real-time. It intended for the internal department to check who brought a toolkit like a cable crimping tool, screwdriver, etc.',
  },
  {
    name: 'Arkadia Web Portal',
    type: '(in-house project)',
    company: 'Arkadia Digital Media Tbk. (2018-present)',
    technologies: 'NodeJS, PHP, VueJS, PWA, Google AMP',
    descriptions:
      'I create several digital media platforms; providing contents in various format for internal and external usage. Each portal is using the adaptive design). These portals are identically one another because they are using the same project structure (includes the stack) but with different content and design.',
  },
  {
    name: 'Media Asset Management',
    type: '(in-house project)',
    company: 'Arkadia Digital Media Tbk. (2018-2020)',
    technologies: 'VueJS',
    descriptions:
      'A MAM system has traditionally been the centerpiece of video or audio workflow, but I created it a bit different, it is a web-based application with support only for images and videos. This application store stuff and make it easier for others (multimedia team member) to find stuff and use what they have found. Whether the final destination is for inclusion in a video, image, or to share to the internal department, the goals are on point; find content quickly and put it to use.',
  },
  {
    name: 'Self-Service Ad Platform',
    type: '(in-house project)',
    company: 'Arkadia Digital Media Tbk. (2018-2021)',
    technologies: 'Python, VueJS',
    descriptions:
      'An advertising platform designed for programmatic ad buying. Advertisers would be able to configure precise targeting impression with various type of ad selections and placements.',
  },
];

const Btn = styled(Button)`
  padding: 10px 20px;
  background-color: #333;
  color: #f1f1f1;
  border-radius: 0;
  transition: 0.2s;
  margin-bottom: 10px;
  :hover,
  :focus,
  :active {
    border: 1px solid #2d2d30;
    background-color: #fff !important;
    color: #000 !important;
  }
`;

const Wrapper = styled.div`
  background: #2d2d30;
  color: #bdbdbd;
  h3 {
    color: #fff;
  }
  p {
    font-style: italic;
  }
`;

const Cards = styled(Card)`
  border: none;
  margin-bottom: 15px;
  p.project-title {
    margin-top: 15px;
  }
`;

const Header = styled(Modal.Header)`
  background-color: #333;
  h4 {
    margin: 0 auto !important;
    background-color: #333;
    color: #fff !important;
    /*text-align: center;*/
    font-size: 30px;
  }
`;

const Works = () => {
  const [show, setShow] = useState(false);
  const [desc, setDesc] = useState([]);

  const handleClose = () => {
    setShow(false);
    setDesc([]);
  };
  const handleShow = (desc) => {
    setShow(true);
    setDesc([desc]);
  };

  const project_list = [];
  for (let i = 0; i < projects.length; i += 3) {
    project_list.push(projects.slice(i, i + 3));
  }

  return (
    <Wrapper className="works">
      <Container>
        <h3 className="text-center">WORKS</h3>
        <p className="text-center">
          Check out some of my projects in the following list.
        </p>
        <div className="projects-list">
          {/* TODO: optimize me */}
          {project_list.map((row, index) => (
            <Row key={index} className="text-center">
              {row.map((item) => (
                <Col xl={4} key={item.name}>
                  <Cards style={{ color: '#555' }}>
                    <p className="project-title">
                      <strong>{item.name}</strong>
                    </p>
                    <Card.Body>
                      <p>{item.technologies}</p>
                      <p>{item.type}</p>
                      <Btn
                        onClick={() =>
                          handleShow({
                            name: item.name,
                            descriptions: item.descriptions,
                            company: item.company,
                          })
                        }
                        variant="secondary"
                        className="btn-desc d-flex justify-content-center d-sm-inline-flex d-md-inline-flex d-lg-inline-flex mx-auto"
                      >
                        Description
                      </Btn>
                    </Card.Body>
                  </Cards>
                </Col>
              ))}
            </Row>
          ))}
        </div>
      </Container>

      <Modals onShow={show} onHandleClose={handleClose} descriptions={desc} />
    </Wrapper>
  );
};

export default Works;

const Modals = ({ onShow, onHandleClose, descriptions }) => {
  return (
    <Modal show={onShow} onHide={onHandleClose}>
      <Header style={{ padding: '40px 50px' }}>
        {descriptions.length !== 0 && <h4>{descriptions[0].name}</h4>}
      </Header>
      <Modal.Body style={{ padding: '40px 50px' }}>
        {descriptions.length !== 0 && (
          <>
            <p
              className="project-desc"
              style={{ textAlign: 'justify', textJustify: 'inter-word' }}
            >
              {descriptions[0].descriptions}
            </p>
            <span>Company: {descriptions[0].company}</span>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Btn variant="secondary" onClick={onHandleClose}>
          Close
        </Btn>
      </Modal.Footer>
    </Modal>
  );
};

Modals.propTypes = {
  onShow: PropTypes.bool.isRequired,
  onHandleClose: PropTypes.func.isRequired,
  descriptions: PropTypes.array.isRequired,
};
